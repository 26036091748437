import React from 'react';
import {Helmet} from "react-helmet";

import sope from "../../assets/bio.jpg"

import logo1 from '../../assets/clientlogo/1.png'
import logo2 from '../../assets/clientlogo/2.png'
import logo3 from '../../assets/clientlogo/3.svg'
import logo4 from '../../assets/clientlogo/4.svg'
import logo5 from '../../assets/clientlogo/5.svg'
import logo6 from '../../assets/clientlogo/6.png'
import logo7 from '../../assets/clientlogo/7.png'
import logo8 from '../../assets/clientlogo/8.png'
import logo9 from '../../assets/clientlogo/9.png'
import logo10 from '../../assets/clientlogo/10.png'
import logo11 from '../../assets/clientlogo/11.png'


import blacklogo1 from '../../assets/blacklogo/1.PNG'
import blacklogo2 from '../../assets/blacklogo/2.PNG'
import blacklogo3 from '../../assets/blacklogo/3.PNG'
import blacklogo4 from '../../assets/blacklogo/4.PNG'
import blacklogo5 from '../../assets/blacklogo/5.PNG'
import blacklogo6 from '../../assets/blacklogo/6.PNG'
import blacklogo7 from '../../assets/blacklogo/7.png'
import blacklogo9 from '../../assets/blacklogo/9.PNG'
import blacklogo10 from '../../assets/blacklogo/10.png'
import blacklogo11 from '../../assets/blacklogo/11.PNG'

import guardian from '../../assets/blacklogo/guardian.JPG'


const Bio = () => {
	return (
		<>
			<Helmet>
		        <title>Sope Adelaja | Photographer - Film Director</title>
		        <meta name="description" content="photographer, photography, portrait, commercial photographer" />
		    </Helmet>
			{/*<div className="xl:h-[300px] lg:h-[300px] md:h-[300px] h-[300px] 
				xl:px-20 xl:py-4 px-4 py-4 bg-[#252525]">
				
			</div>*/}

			<div className="grid md:grid-cols-2 grid-cols-1 xl:px-[15em] lg:px-10 md:px-10 px-4 py-10 bg-[#252525] gap-8">
				<div className="py-4 mb-6 order-2 md:order-1">
					<div className="border-b border-[#b7a78b] py-4 mb-6">
						<p
							className="uppercase text-2xl text-[#b7a78b] font-semibold tracking-[.15em]"
							style={{ fontFamily: "Hanken Grotesk" }}
						>
							about me
						</p>
					</div>
					<div>
						<p className="mb-4 text-white text-[15px]">
							Sope Adelaja is a Nigerian-born multidisciplinary artist with over 7-year-track record of expression in photography
							and film who has evolved into becoming a household name of repute. Sope, from a young age has constantly found himself
							caught between dreams and ideas. Inspired by life and his experiences, Sope’s signature lifestyle/portraiture style
							of photography forced him into a quest of deeper appreciation while the capturing of core human anthropologic expression.
						</p>
						<p className="mb-4 text-white text-[15px]">
							From the age of 18, Sope began using photography as a tool for self-expression, portraying profound thoughts that are
							often left unspoken into visual narratives. His curiosity about different ways of living has brought him to new and
							unfamiliar territories, including targeted areas in North-eastern Nigeria. During a trip undertaken for the United
							Nations Development Programme (UNDP), he turned his lens on Internally Displaced Persons (IDPs) affected by the Boko
							Haram insurgency and conflicts involving Non-State Armed Groups (NSAG), which served as a catalyst for ongoing interventions.
							With his unique approach, the Nigerian-born multidisciplinary artist has been consistently sought after by prominent bodies
							and notable organizations, including NPR for their 2024 Global Food Crisis Report, BBC Africa, GAVI, the United Nations
							International Children’s Fund (UNICEF), and many others.
						</p>
						<p className="text-white text-[15px]">
							As a firm believer in the transformational power of capturing moments in still images and cinematography, Sope is passionate
							about catalyzing change deeply-rooted in cultural practices across the globe. He also works on commissioned and commercial
							projects with select brands and bodies.
						</p>
					</div>
				</div>
				<div className="flex items-center justify-center order-1 md:order-2">
					<img
						src={sope}
						alt="Sope Adelaja"
						className="rounded-full w-[20em] h-[20em] sm:w-[20em] sm:h-[20em] md:w-60 md:h-60 lg:w-[30em] lg:h-[30em] object-cover"
					/>
				</div>
			</div>


			
			<div className="flex flex-col xl:flex-row xl:px-[15em] lg:px-10 md:px-10 px-4 py-10 bg-[#252525] gap-8">

			  {/* Recent Press Section */}
			  <div className="flex flex-col w-full lg:w-[300px] mx-auto bg-[#252525] gap-4 order-1 xl:order-2">
			  	<div className="border-b border-[#b7a78b] py-4 mb-2">
					<h2
				    	className="uppercase text-[#b7a78b] text-2xl font-semibold tracking-[.15em]"
					    style={{ fontFamily: "Hanken Grotesk" }}
					>
					    recent press
					</h2>
				</div>
			    <div className="py-4">
			      <img className="h-[100px] mb-4" src={guardian} alt="header" />
			      <p className="text-[#efefcb] mb-4 ">
			        <span className="capitalize">'in her hands' exhibition:</span> Sope Adelaja's artistic vision of nurture and sustainability
			      </p>
			      <a href="https://guardian.ng/life/in-her-hands-exhibition-sope-adelajas-artistic-vision-of-nurture-and-sustainability/" 
			      	target="_blank"
			      	rel="noreferrer"
			      	className="border border-[#efefcb] px-4 py-2 text-[13px] text-[#efefcb] uppercase">
			        read more
			      </a>
			    </div>
			  </div>
			  {/* Client Section */}
			  <div className="flex flex-col flex-1 gap-8 order-2 xl:order-1">
			  	<div>
				    <div className="border-b border-[#b7a78b] py-4 mb-6">
				      <p
				        className="uppercase text-2xl text-[#b7a78b] font-semibold tracking-[.15em]"
				        style={{ fontFamily: "Hanken Grotesk" }}
				      >
				        client
				      </p>
				    </div>
					<div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-8">
					  {[blacklogo1, blacklogo2, blacklogo3, blacklogo4, blacklogo5, blacklogo6, blacklogo7, logo8, blacklogo9, blacklogo10, blacklogo11].map((logo, index) => (
					    <div
					      key={index}
					      className="flex items-center justify-center xl:w-[150px] xl:h-[150px] w-[160px] h-[160px] bg-[#939292] hover:bg-[#ccc] transition cursor-pointer shadow-md mx-auto"
					    >
					      <img src={logo} alt={`logo-${index + 1}`} className="max-w-[80%] max-h-[80%] object-contain" />
					    </div>
					  ))}
					</div>
				</div> 
			  </div>
			</div>

			{/*EXHIBITION AND PUBLICATION*/}
			<div className="flex flex-col xl:flex-row xl:px-[15em] lg:px-10 md:px-10 px-4 py-10 bg-[#252525] gap-8">
				<div className="flex flex-col w-full  mx-auto bg-[#252525] gap-4">
				  	<div className="border-b border-[#b7a78b] py-4 mb-2">
						<h2
					    	className="uppercase text-[#b7a78b] text-2xl font-semibold tracking-[.15em]"
						    style={{ fontFamily: "Hanken Grotesk" }}
						>
						    publications
						</h2>
					</div>
				    <div className="py-4">
				      <p className="text-[#efefcb] mb-4">
				      	Sope Adelaja's Artistic Vision of Nurture and Sustainability - <i>The Guardian Life, December 2024.</i>
				      	<a href="https://guardian.ng/life/in-her-hands-exhibition-sope-adelajas-artistic-vision-of-nurture-and-sustainability/#google_vignette" target="_blank" rel="noreferrer"
				      		className="text-[#efefcb] underline decoration-current capitalize ml-2">link</a>
				      </p>
				      <p className="text-[#efefcb] mb-4">
				      	Global Food Crisis 2024 - <i>NPR, October 2024.</i>
				      	<a href="https://www.npr.org/sections/goats-and-soda/2024/09/25/g-s1-24488/photos-childhood-food-poverty-malnutrition" 
				      		target="_blank" rel="noreferrer"
				      		className="text-[#efefcb] capitalize underline decoration-current ml-2">link</a>
				      </p>
				      <p className="text-[#efefcb] mb-4">
				      	The Ojude Oba Festival - <i>BBC Africa, June 2024.</i>
				      	<a href="https://www.instagram.com/p/C8e9OOJojic/?igsh=MW0yZTJlc2xlb2hucQ%3D%3D&img_index=1" target="_blank" rel="noreferrer"
				      		className="text-[#efefcb] capitalize underline decoration-current ml-2">link</a>
				      </p>
				      <p className="text-[#efefcb] mb-4">
				      	Festival Grounds: The Durbar Festical - <i>Culture Custodian, June 2024.</i>
				      	<a href="https://culturecustodian.com/festival-grounds-feel-the-aura-of-the-2024-durbar-festival-in-zaria/" target="_blank" rel="noreferrer"
				      		className="text-[#efefcb] capitalize underline decoration-current ml-2">link</a>
				      </p>
				      <p className="text-[#efefcb] mb-4">
				      	Cover - ThisDay Style Magazine, June 2024
				      	<a href="https://www.thisday.com/" target="_blank" rel="noreferrer"
				      		className="text-[#efefcb] capitalize underline decoration-current ml-2">link</a>
				      </p>
				    </div>
			    </div>
				<div className="flex flex-col flex-1 gap-8">
				  	<div>
					    {/*<div className="border-b border-[#b7a78b] py-4 mb-6">
					      <p
					        className="uppercase text-2xl text-[#b7a78b] font-semibold tracking-[.15em]"
					        style={{ fontFamily: "Hanken Grotesk" }}
					      >
					        exhibition
					      </p>
					    </div>
						<div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-4">
						    
						</div>*/}
					</div>
				</div>

			</div>

{/* Client Section */}



		</>
	)
}

export default Bio